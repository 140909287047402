<template>
  <o-container ref="container">
    <v-card elevation="2" shaped class=" fill-height">
      <v-card-title v-if="action === ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto ">
          {{ title }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-plus"
            type="label-icon"
            label="Add New"
            @on-click="add()"
          />

            <o-button
            :busy="busy"
            color="warning"
            icon="mdi-filter"
            type="label-icon"
            label="Filter"
            @on-click="showFilter = true"            
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-sync"
            type="label-icon"
            label="Refresh"
            @on-click="reset()"
          />
        </div>

        <o-drawer
            v-if="!busy"
            :auto-close="true"
            :actions="[
              {
                key: 'on-item-reset',
                label: 'RESET',
                icon: 'mdi-refresh',
                color: 'warning',
              },
              {
                key: 'on-item-search',
                label: 'SEARCH',
                icon: 'mdi-text-box-search-outline',
                color: 'success',
              },
            ]"
            button-icon="mdi-filter-plus-outline"
            :title="`FILTERS`"
            :use-button="false"
            width="50vw"
            :visible="showFilter"
            @on-item-search="
              showFilter = false;
              reset();
            "
            @on-item-reset="
              showFilter = false;
              reset(true);
            "
            @on-close="showFilter = false"
          >
            <v-row class="pa-0 ma-0"> 
              <v-col cols="12">
                <o-input
                  v-model="parameters.permission"
                  :busy="busy"
                  label="Endpoint"
                />
              </v-col>
            </v-row>
        </o-drawer>

      </v-card-title>
      <v-divider v-if="action === ''" />

      <v-card-text v-if="action === ''" class="text-primary">
        <o-table
          ref="table"
          type="rest"
          :lazy="true"
          :busy="busy"
          :has-paging="true"
          :height="getHeight(442)"
          :fixed-header="true"
          method="POST"
          :rest="{
            url: `/rest/intranet/list`,
            payload: listParameters,
          }"
          :headers="[
            {
              text: '#',
              align: 'end',
              value: 'row_no',
              type: 'int',
              width: 100,
            },
            {
              text: 'Endpoint',
              align: 'start',
              sortable: false,
              value: 'permission',
              type: 'text',
            }, 
            {
              text: 'Mandatory',
              align: 'center',
              sortable: false,
              value: 'mandatory',
              type: 'boolean',
            },
            {
              text: 'Dependency',
              align: 'start',
              sortable: false,
              value: 'dependency',
              type: 'boolean',
            },
            {
              text: 'Default Methods',
              align: 'start',
              sortable: false,
              value: 'default_methods',
              type: 'string-array',
            },
            {
              text: 'Allowed Methods',
              align: 'start',
              sortable: false,
              value: 'allowed_methods',
              type: 'string-array',
            },
            {
              value: 'action',
              type: 'action',
              width: 100,
              actions: [
                {
                  key: 'on-item-edit',
                  label: 'Edit',
                  icon: 'mdi-pencil-box-outline',
                  color: 'success',
                },
                {
                  key: 'on-item-delete',
                  label: 'Delete',
                  icon: 'mdi-trash-can-outline',
                  color: 'danger',
                },
              ],
            }
          ]"
          @set-busy="setBusy"
          @on-item-edit="edit"
          @on-item-delete="remove"
        >
          <template v-slot:slot="row">
            <div
              v-if="row.prop.key === 'inventory_source_name'"
              style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;"
            >
              <template v-if="row.prop.item.inventory_source_id > 0">
                {{ row.prop.item.inventory_source_name }} ({{
                  row.prop.item.inventory_source_id
                }})
              </template>
            </div>            
          </template>
        </o-table>
      </v-card-text>

      <v-card-title v-if="action !== ''" class="d-flex justify-space-between">
        <div class="text-uppercase pl-4 mr-auto">
          {{ `${action} - ${title}` }}
        </div>
        <div class="d-flex justify-end ml-auto pr-0 pb-2">
          <o-button
            :busy="busy"
            color="green"
            icon="mdi-content-save"
            type="label-icon"
            label="Save"
            @on-click="save()"
          />

          <o-button
            :busy="busy"
            color="primary"
            icon="mdi-keyboard-backspace"
            type="label-icon"
            label="Back"
            @on-click="action = ''"
          />
        </div>
      </v-card-title>

      <v-divider v-if="action !== ''" />

      <div v-if="action !== ''" class="ml-5 pb-5 mr-5">
        <v-row class="o-container-content">
          <v-col cols="12" class="pa-10 pr-15">
            <v-form ref="form" v-model="valid" lazy-validation>
             
              <o-input
                v-model="model.permission"
                :busy="busy"
                label="Endpoint"
                :is-required="true"
                :rules="[(v) => !!v || 'This is required']"
              />

              <o-check-box
                v-model="model.mandatory"
                :busy="busy"
                label="Mandatory"
                style="display:inline-block"
              />

              <v-row cols="12" class="pl-0 pr-0 pt-0 pb-0">
                <v-col cols="4" class="pt-1 pr-15 pb-0 pl-4">
                  Default
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.default_methods.get"
                    :busy="busy"
                    label="Read"
                    style="display:inline-block"
                  />
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.default_methods.post"
                    :busy="busy"
                    label="Write"
                    style="display:inline-block"
                  />
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.default_methods.put"
                    :busy="busy"
                    label="Update"
                    style="display:inline-block"
                  />
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.default_methods.delete"
                    :busy="busy"
                    label="Delete"
                    style="display:inline-block"
                  />
                </v-col>  
              </v-row> 

              <v-row cols="12" class="pl-0 pr-0 pt-0 pb-0">
                <v-col cols="4" class="pt-1 pr-15 pb-0 pl-4">
                  Allowed Method
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.allowed_methods.get"
                    :busy="busy"
                    label="Read"
                    style="display:inline-block"
                  />
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.allowed_methods.post"
                    :busy="busy"
                    label="Write"
                    style="display:inline-block"
                  />
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.allowed_methods.put"
                    :busy="busy"
                    label="Update"
                    style="display:inline-block"
                  />
                </v-col>  
                <v-col cols="2" class="pa-0 pr-15">
                  <o-check-box
                    v-model="model.allowed_methods.delete"
                    :busy="busy"
                    label="Delete"
                    style="display:inline-block"
                  />
                </v-col>  
              </v-row> 
 
            </v-form>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </o-container>
</template>

<script>
import RestApi from "@/services/RestApi";
import { mapGetters } from "vuex";

export default {
  name: "ApiEndpoint",
  mixins: [RestApi],
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "API Endpoints",
  },
  data() {
    return {
      title: "API Endpoints",
      busy: false,
      showFilter: false,
      strictFilter: false,
      action: "",
      valid: false,
      baseModel: {
        id: 0,
        permission_id: 0,
        permission: '',
        mandatory: false,
        default: 0,
        allowed: 0,
        dependency: false,
        default_methods: {
          get: false,
          post: false,
          put: false,
          delete: false
        },
        allowed_methods: {
          get: false,
          post: false,
          put: false,
          delete: false
        },
      },
      model: {},
      originalModel: {}, 
      parameters: {
        permission: ''
      },
      inventory_sources: [],
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  watch: {},
  created() {
    this.listParameters.fields = [
      "ROW_NUMBER() OVER(ORDER BY a.permission) row_no",
      "a.*",
      `CASE ("default")
            WHEN 1 THEN ARRAY['GET']
            WHEN 2 THEN ARRAY['POST']
            WHEN 3 THEN ARRAY['GET', 'POST']
            WHEN 4 THEN ARRAY['PUT']
            WHEN 5 THEN ARRAY['GET', 'PUT']
            WHEN 6 THEN ARRAY['PUT', 'POST']
            WHEN 7 THEN ARRAY['GET', 'POST', 'PUT']
            WHEN 8 THEN ARRAY['DELETE']
            WHEN 9 THEN ARRAY['GET', 'DELETE']
            WHEN 10 THEN ARRAY['POST', 'DELETE']
            WHEN 11 THEN ARRAY['GET', 'POST', 'DELETE']
            WHEN 12 THEN ARRAY['PUT', 'DELETE']
            WHEN 13 THEN ARRAY['GET', 'PUT', 'DELETE']
            WHEN 14 THEN ARRAY['PUT', 'POST', 'DELETE']
            WHEN 15 THEN ARRAY['GET', 'POST', 'PUT', 'DELETE']
            ELSE '{}'
        END AS default_methods,
        CASE (case when allowed > 0 then allowed else "default" end)
            WHEN 1 THEN ARRAY['GET']
            WHEN 2 THEN ARRAY['POST']
            WHEN 3 THEN ARRAY['GET', 'POST']
            WHEN 4 THEN ARRAY['PUT']
            WHEN 5 THEN ARRAY['GET', 'PUT']
            WHEN 6 THEN ARRAY['PUT', 'POST']
            WHEN 7 THEN ARRAY['GET', 'POST', 'PUT']
            WHEN 8 THEN ARRAY['DELETE']
            WHEN 9 THEN ARRAY['GET', 'DELETE']
            WHEN 10 THEN ARRAY['POST', 'DELETE']
            WHEN 11 THEN ARRAY['GET', 'POST', 'DELETE']
            WHEN 12 THEN ARRAY['PUT', 'DELETE']
            WHEN 13 THEN ARRAY['GET', 'PUT', 'DELETE']
            WHEN 14 THEN ARRAY['PUT', 'POST', 'DELETE']
            WHEN 15 THEN ARRAY['GET', 'POST', 'PUT', 'DELETE']
            ELSE '{}'
        END AS allowed_methods`
    ];
    this.listParameters.sources = {
      main: "permissions a",
      children: [
  
      ],
    };
    this.listParameters.orders = [
      {
        sort: "a.permission",
        dir: "ASC",
      }, 
    ];
     this.listParameters.paging = {
      page: 1,
      page_size: 20
    }; 
  },
  async mounted() {
    
  },
  methods: {
    add() {
      this.action = "add";
      this.model = _.cloneDeep(this.baseModel);
    },
    edit(item) {
      
      this.originalModel = _.cloneDeep(item);      
      this.model = _.cloneDeep(item);
      switch (this.model.default) {
        case 1: this.model.default_methods = { get: true, post: false, put: false, delete: false }; break;
        case 2: this.model.default_methods = { get: false, post: true, put: false, delete: false }; break;
        case 3: this.model.default_methods = { get: true, post: true, put: false, delete: false }; break;
        case 4: this.model.default_methods = { get: false, post: false, put: true, delete: false }; break;
        case 5: this.model.default_methods = { get: true, post: false, put: true, delete: false }; break;
        case 6: this.model.default_methods = { get: false, post: true, put: true, delete: false }; break;
        case 7: this.model.default_methods = { get: true, post: true, put: true, delete: false }; break;
        case 8: this.model.default_methods = { get: false, post: false, put: false, delete: true }; break;
        case 9: this.model.default_methods = { get: true, post: false, put: false, delete: true }; break;
        case 10: this.model.default_methods = { get: false, post: true, put: false, delete: false }; break;
        case 11: this.model.default_methods = { get: true, post: true, put: false, delete: true }; break;
        case 12: this.model.default_methods = { get: true, post: false, put: false, delete: false }; break;
        case 13: this.model.default_methods = { get: false, post: false, put: true, delete: true }; break;
        case 14: this.model.default_methods = { get: false, post: false, put: true, delete: true }; break;
        case 15: this.model.default_methods = { get: true, post: true, put: true, delete: true }; break;
        default : this.model.default_methods = { get: false, post: false, put: false, delete: false }; 
      }

      let allowed = (this.model.allowed > 0 ? this.model.allowed : this.model.default )
      switch (allowed) {
        case 1: this.model.allowed_methods = { get: true, post: false, put: false, delete: false }; break;
        case 2: this.model.allowed_methods = { get: false, post: true, put: false, delete: false }; break;
        case 3: this.model.allowed_methods = { get: true, post: true, put: false, delete: false }; break;
        case 4: this.model.allowed_methods = { get: false, post: false, put: true, delete: false }; break;
        case 5: this.model.allowed_methods = { get: true, post: false, put: true, delete: false }; break;
        case 6: this.model.allowed_methods = { get: false, post: true, put: true, delete: false }; break;
        case 7: this.model.allowed_methods = { get: true, post: true, put: true, delete: false }; break;
        case 8: this.model.allowed_methods = { get: false, post: false, put: false, delete: true }; break;
        case 9: this.model.allowed_methods = { get: true, post: false, put: false, delete: true }; break;
        case 10: this.model.allowed_methods = { get: false, post: true, put: false, delete: false }; break;
        case 11: this.model.allowed_methods = { get: true, post: true, put: false, delete: true }; break;
        case 12: this.model.allowed_methods = { get: true, post: false, put: false, delete: false }; break;
        case 13: this.model.allowed_methods = { get: false, post: false, put: true, delete: true }; break;
        case 14: this.model.allowed_methods = { get: false, post: false, put: true, delete: true }; break;
        case 15: this.model.allowed_methods = { get: true, post: true, put: true, delete: true }; break;
        default : this.model.allowed_methods = { get: false, post: false, put: false, delete: false }; 
      }
      this.action = "edit";
    },
    async remove(item) {
      this.model = _.cloneDeep(item);
      await this.$refs.container
        .confirm(
          "Confirmation",
          `You are deleting <strong>${this.model.permission} </strong>. Do you want to continue?`,
          "red"
        )
        .then(async (ans) => {
          if (ans) {
            const url = "/rest/intranet/query"; 
            const parameters = _.cloneDeep(this.deleteParameters);
            parameters.table = 'permissions'; 
            parameters.wheres.push({field: 'id', value: this.model.id });
            
            await this.postData(url, parameters)
              .then((response) => {
                this.$refs.container.snackBar(
                  `
                The record of ${this.model.permission} was successfully deleted.
                `,
                  "red",
                  2000
                );
                this.updateRolesPermission('');
                this.reset();
              })
              .catch((err) => {
                this.$refs.container.snackBar(
                  `
                Error updating item: ${errors.join(",")}
                `,
                  "red",
                  2000
                );
              });
          }
        });
    },
    async save() {
      if (!this.$refs.form.validate()) return false;
      const errors = [];
      const url = "/rest/intranet/query";
      const model = _.cloneDeep(this.model);

      let default_value = 0;
      if (this.model.default_methods.get) default_value++;
      if (this.model.default_methods.post) default_value += 2;
      if (this.model.default_methods.put) default_value += 4;
      if (this.model.default_methods.delete) default_value += 8;

      let allowed_value = 0;
      if (this.model.allowed_methods.get) allowed_value++;
      if (this.model.allowed_methods.post) allowed_value += 2;
      if (this.model.allowed_methods.put) allowed_value += 4;
      if (this.model.allowed_methods.delete) allowed_value += 8;

      if (this.action === "add") {

        const checker = await this.getList(
          ["permission"],
          "permissions",
          [`permission = '${model.permission.replace(/'/g, "''")}'`],
          [],
          "permission"
        );
        if (checker.length > 0) {
          this.$refs.container.snackBar(
              `Unable to add ${model.permission} already exists.`,
              "red",
              2000
            );
          return;
        }
       
        let parameters = _.cloneDeep(this.addParameters);
        parameters.table = 'permissions';
        parameters.fields.push({field: 'permission', value: this.model.permission });
        parameters.fields.push({field: 'mandatory', value: this.model.mandatory });
        parameters.fields.push({field: 'default', value: default_value });
        parameters.fields.push({field: 'allowed', value: allowed_value });        

        await this.postData(url, parameters)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully added ${model.permission}.`,
              "green",
              2000
            );
            this.updateRolesPermission(model.permission);
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          }); 
      }
    
       
      if (this.action === "edit") {         
        const model = _.cloneDeep(this.model);

        const parameters = _.cloneDeep(this.updateParameters);
        parameters.table = 'permissions';
        parameters.fields.push({field: 'permission', value: model.permission });
        parameters.fields.push({field: 'mandatory', value: this.model.mandatory });
        parameters.fields.push({field: 'default',value: default_value});
        parameters.fields.push({field: 'allowed', value: allowed_value });

        parameters.wheres.push({field: 'id', value: this.originalModel.id });

        await this.postData(url, parameters)
          .then((response) => {
            this.$refs.container.snackBar(
              `Successfully updated ${this.originalModel.permission} into ${model.permission}.`,
              "green",
              2000
            ); 
            this.reset();
          })
          .catch((err) => {
            errors.push(err.data.message);
          });
      }

      if (errors.length > 0) {
        this.$refs.container.snackBar(
          `Error updating item: ${errors.join(",")}`,
          "red",
          2000
        );
      } else {
        this.reset();
      }
    },
    reset(clear) {      
      this.listParameters.wheres = [];
      if (clear) {
        this.parameters = {permission : ''}
      }
      const wheres = [];
      if (this.parameters.permission != '') {
        wheres.push(this.getWhereString('a.permission', this.parameters.permission))
      }

      if (wheres.length > 0) this.listParameters.wheres = [wheres.join(` ${this.strictFilter? ' AND ': ' OR '} `)]      

      this.action = "";
      this.model = _.cloneDeep(this.baseModel);
      setTimeout(async () => {
        this.$refs.table.refresh();
      }, 200);
    },     
  },
};
</script>
<style lang="scss" scoped>
.o-container-content {
  min-height: calc(100vh - 278px);
  max-height: calc(100vh - 278px);
  overflow-y: scroll;
}
</style>
