<template>
  <ApiEndpoint dialog-name="API Endpoints" />
</template>
<script>
import ApiEndpoint from '@/views/app/api/Endpoint'

export default {
  name: 'ApiEndpointWrapper',
  components: {
    ApiEndpoint
  }
}
</script>
